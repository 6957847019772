import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { CircularProgress } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AppSettings } from '../../AppSettings';
import AutoVisible from '../../AutoVisible';
import PanBackground from '../../PanBackground';
import useScroll from '../../../utils/useScroll';
import { imageLoader } from '../../../utils/useImageLoader';
import './AppMainLogo.css';

interface AppMainLogoProps {
    settings: AppSettings;
}

export function AppMainLogoMinimal(props: AppMainLogoProps) {
    return (
        <Box id='welcome' className={"AppMainLogo"}>
            <PanBackground className="Background" image={'main'} width={3840} animate={false} />
            <Box className="Animation MidShort" style={{opacity: 1}}>
                <CircularProgress color="dark" />
            </Box>
        </Box>
    );
}

export default function AppMainLogo(props: AppMainLogoProps) {
    const { t } = useTranslation();

    const mainRef = React.useRef<HTMLElement>(null);

    useScroll(() => {
        const savedOffset = sessionStorage.getItem('pageYOffset');
        const pageYOffset = (savedOffset) ? parseInt(savedOffset) : window.pageYOffset;
        if (pageYOffset <= window.innerHeight) {
            if (mainRef.current) {
                mainRef.current.classList.add('Visible');
            }
        } else if (mainRef.current) {
            mainRef.current.classList.remove('Visible');
        }
    }, [mainRef], true);

    return <AutoVisible innerRef={mainRef} component={Box} props={{id: 'welcome', className: "AppMainLogo Fixed"}}>
        <PanBackground className="Background" image={'main'} width={3840} lazy={false} />
        <Box className="Contents Animation" data-transition="short visibility">
            <div className="Flex Move Animation" data-transition="xshort">
                <div className="WelcomeContainer Animation">
                    <Typography className="Welcome" color="dark" gutterBottom variant="h3" component="div">
                        <Trans i18nKey="main.logo.welcome" />
                    </Typography>
                </div>
            </div>
            <div className="Animation Move" data-transition="xshort">
                <Box className="LogoContainer Animation" data-transition="long visibility">
                    <div className="Icon Animation" data-transition="long">
                        <img src={imageLoader('logo_icon')} alt={t('main.logo.alt.icon')} />
                    </div>
                    <Box className="Container">
                        <div className="Logo Animation" data-transition="long">
                            <img className="AppMainLogoImage" src={imageLoader('logo_main_light')} alt={t('main.logo.alt.image')} />
                        </div>

                        <div className="Mirror Animation" data-transition="long">
                            <img src={imageLoader('logo_main_light')} alt={t('main.logo.alt.image')} />
                        </div>
                    </Box>
                </Box>
            </div>
            <div className="Flex LearnMoreContainer">
                <Button variant="contained" color="light" size="large" component={Link} to='/#cedomer'><Trans i18nKey="main.logo.learnmore" /></Button>
            </div>
            <div className="DownArrow Animation" data-transition="long visibility">
                <IconButton color="light" component={Link} to='/#cedomer' size="large">
                    <KeyboardArrowDownIcon />
                </IconButton>
            </div>
        </Box>
    </AutoVisible>;
}