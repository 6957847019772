import React from 'react';
import cn from 'classnames';
import getRandom from '../utils/getRandom';
import AutoVisible from './AutoVisible';
import LazyBackground from './LazyBackground';
import './PanBackground.css';

export interface PanBackgroundProps extends React.HTMLAttributes<HTMLDivElement> {
    image: string;
    width: number;
    animate?: boolean;
    lazy?: boolean;
}

interface BackgroundState {
    pos: number;
    duration: number;
    saved?: {
        pos: number;
        duration: number;
    } | null;
    transition?: string | null;
    start?: number | null;
    elapsed?: number | null;
};

export default function PanBackground(props: PanBackgroundProps) {
    const { image, width, animate, lazy, ...rest } = {...{animate: true, lazy: true}, ...props};

    const bgRef = React.useRef<HTMLDivElement>(null);

    const [ currentTarget, setCurrentTarget ] = React.useState<BackgroundState>({pos: getRandom(40, 60), duration: 0});

    const handleBgTransition = () => {
        const target = bgRef.current;
        if (animate && target) {
            const computed = window.getComputedStyle(target)
            let currentPosition = Number(computed.getPropertyValue(computed.getPropertyValue('--property')).replace('%', ''));
            const nextPosition = (currentPosition >= 50) ? getRandom(0, 49) : getRandom(51, 100);
            const ratio = (nextPosition > currentPosition) ? (currentPosition / Math.max(1, nextPosition)) : (nextPosition / Math.max(currentPosition, 1));
            const duration = Math.floor((1.0 - ratio) * getRandom(20000, 40000));
            setCurrentTarget({pos: nextPosition, duration: duration, start: Date.now()});
        }
    };

    const loaded = React.useRef(false);
    const resumeAnimation = () => {
        if (!loaded.current) {
            return;
        }

        const target = bgRef.current;
        if (target && currentTarget.saved) {
            setCurrentTarget({
                ...currentTarget,
                pos: currentTarget.saved.pos,
                transition: null,
                start: Date.now()
            });
        } else {
            handleBgTransition();
        }
    };

    const pauseAnimation = () => {
        if (!loaded.current) {
            return;
        }

        const target = bgRef.current;
        if (target) {
            const computed = window.getComputedStyle(target);
            const elapsed = (currentTarget.elapsed ?? 0) + (Date.now() - (currentTarget.start ?? 0));
            const saved = currentTarget.saved ?? {pos: currentTarget.pos, duration: currentTarget.duration};
            setCurrentTarget({
                ...currentTarget,
                pos: Number(computed.getPropertyValue(computed.getPropertyValue('--property')).replace('%', '')),
                duration: saved.duration - elapsed,
                saved: saved,
                transition: 'none',
                elapsed: elapsed
            });
        }
    };

    const onLoad = () => {
        if (!loaded.current) {
            loaded.current = true;
            resumeAnimation();
        }
    };

    return (
        <AutoVisible {...(animate && {onEnter: () => resumeAnimation(), onLeave: () => pauseAnimation()})}>
            <div data-transition="short visibility" {...rest} className={cn(props.className, "PanBackground", "Animation", {Visible: loaded.current})}>
                <LazyBackground
                    innerRef={bgRef}
                    className={"Animation"}
                    image={props.image}
                    style={
                        {
                            "--pan-width": width + 'px',
                            "--target": currentTarget.pos + '%',
                            "--transition": currentTarget.transition,
                            "--duration": currentTarget.duration + 'ms'
                        } as React.CSSProperties
                    }
                    lazy={lazy}
                    heights={[720, 1080, 1440]}
                    onLoad={() => onLoad()}
                    onTransitionEnd={() => {handleBgTransition()}} />
            </div>
        </AutoVisible>
    );
}
