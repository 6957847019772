import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { HttpBackendOptions } from 'i18next-http-backend';
import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector';

import i18nSettings from './settings';
import i18nResources from './resources';

const domainDetector = {
    name: 'domain',
    lookup(options: DetectorOptions) {
        for (const [lang, domain] of Object.entries(i18nSettings.domain)) {
            if (window.location.hostname.endsWith(domain) || window.location.hostname.endsWith(domain + '.localhost')) {
                return lang;
            }
        }

        return '';
    }
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(domainDetector);

i18n
    .use(languageDetector)
    .use(initReactI18next)
    .init<HttpBackendOptions>({
        ns: ['translation', 'common'],
        supportedLngs: i18nSettings.supported,
        load: 'languageOnly',
        fallbackLng: i18nSettings.fallback,
        defaultNS: 'translation',
        debug: false,
        detection: {
            order: ['localStorage', 'sessionStorage', 'path', 'domain', 'htmlTag', 'navigator'],
            caches: ['localStorage']
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: i18nResources
    });

export default i18n;