import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation} from 'react-router-dom';
import { default as cn } from 'classnames';
import { Box, IconButton } from '@mui/material';
import { Drawer } from '@mui/material';
import { List , ListItem, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import PhoneIcon from '@mui/icons-material/Phone';
import CheckIcon from '@mui/icons-material/Check';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { AppSettings } from '../AppSettings';
import i18nFlags from '../../i18n/flags';
import useScroll from '../../utils/useScroll';
import './AppMenu.css';

interface AppMenuProps {
    settings: AppSettings;
    language: string;
    menuOpen: boolean;
    toggleColorMode: () => void;
    setLanguage: (l: string) => void;
}

function MenuButton(props: {menuOpen: boolean}) {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const [ headerVisible, setHeaderVisible ] = React.useState(false);
    useScroll(() => {
        const savedOffset = sessionStorage.getItem('pageYOffset');
        const pageYOffset = (savedOffset) ? parseInt(savedOffset) : window.pageYOffset;
        if (pageYOffset >= window.innerHeight / 2) {
            if (!headerVisible) {
                setHeaderVisible(true);
            }
        } else if (headerVisible) {
            setHeaderVisible(false);
        }
    }, [headerVisible], true, false);

    return (
        <IconButton
            className="Button Animation"
            color={(theme.palette.mode === 'dark' && (headerVisible || props.menuOpen)) ? "light" : "dark"}
            aria-label={t('mainmenu.icon.open')}
            size="large"
            onClick={() => {props.menuOpen ? navigate(-1) : navigate(location, {state: {menu: true}})}}>
            <MenuIcon />
        </IconButton>
    );
}

export default function AppMenu(props: AppMenuProps) {
    const { t } = useTranslation();
    const theme = useTheme();

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Box component="nav" className="AppMenu">
            <div className="Icon">
                <MenuButton menuOpen={props.menuOpen} />
            </div>

            <Drawer
                className={"Drawer"}
                PaperProps={{
                    elevation: 3
                }}
                variant="persistent"
                anchor="right"
                elevation={3}
                open={props.menuOpen}>

                <List>
                    <ListItem key={'welcome'} disablePadding>
                        <ListItemButton className="MenuItem" component={Link} to='/#welcome' replace={true}>
                            <ListItemIcon>
                                <WavingHandIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('mainmenu.item.welcome')} primaryTypographyProps={{variant: 'h5', sx: {fontWeight: '200'}}} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key={'contact'} disablePadding>
                        <ListItemButton className="MenuItem" onClick={() => navigate(location, {replace: true, state: {modal: true, contact: true}})}>
                            <ListItemIcon>
                                <PhoneIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('mainmenu.item.contact')} primaryTypographyProps={{variant: 'h5', sx: {fontWeight: '200'}}} />
                        </ListItemButton>
                    </ListItem>
                </List>

                <Box sx={{flex: 1}} />
                <Box className="BottomBar">
                    <IconButton className="DarkMode" onClick={props.toggleColorMode}>
                        <LightModeIcon data-transition="visibility" className={cn("Animation", {Visible: (theme.palette.mode === 'dark')})} />
                        <DarkModeIcon data-transition="visibility" className={cn("Animation", {Visible: (theme.palette.mode !== 'dark')})} />
                    </IconButton>
                    <Box sx={{flex: 1}} />
                    <Box className="LanguageBar">
                        {i18nFlags().map((lng) => (
                            <IconButton className={cn({Selected: props.language === lng.lang})} key={lng.lang} title={t('common:language.label.' + lng.lang)} size="large" onClick={() => { props.setLanguage(lng.lang) }}>
                                <img src={lng.icon} className='Flag Animation' alt={t('common:language.label.' + lng.lang)} width="24" height="18" />
                                <CheckIcon data-transition="visibility" className="CheckMark Animation" />
                            </IconButton>
                        ))}
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
}
