export interface AppSettings {
    testSuspense: boolean;
    headerHeight: number;
    mainCardSwipeWidth: number;
    mainLogoHeight: number;
    mainLogoGap: number;
};

const settings: AppSettings = {
    testSuspense: false,
    headerHeight: 64,
    mainCardSwipeWidth: 200,
    mainLogoHeight: 64,
    mainLogoGap: 48
};

export default settings;