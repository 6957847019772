import React from 'react';
import { default as cn } from 'classnames';
import AutoVisible from './AutoVisible';
import LazyBackground from './LazyBackground';
import './ImageCarousel.css';

export interface ImageCarouselProps extends React.HTMLAttributes<HTMLDivElement> {
    images: string[];
    widths?: number[];
    timer?: number;
    lazy?: boolean;
}

export default function ImageCarousel(props: ImageCarouselProps) {
    const { images, widths, timer, lazy, ...rest } = {...{timer: 5000, lazy: true}, ...props};

    const visibleRef = React.useRef<HTMLDivElement>(null);
    const containerRef = React.useRef<HTMLDivElement>(null);

    const activeIndex = React.useRef(0);
    const timeID = React.useRef(0);

    const startTimer = () => {
        if (props.images.length <= 1) {
            return;
        }

        const handler = () => {
            activeIndex.current = (activeIndex.current >= props.images.length - 1) ? 0 : (activeIndex.current + 1);
            if (containerRef.current) {
                containerRef.current.style.transform = 'translateX(-' + (activeIndex.current * 100) + '%)';
            }
        };

        timeID.current = window.setInterval(handler, timer);
        return () => {
            window.clearInterval(timeID.current);
            timeID.current = 0;
        };
    };

    const resume = () => {
        if (props.images.length > 1 && timeID.current === 0) {
            startTimer();
        }

        if (containerRef.current) {
            containerRef.current.classList.remove('NoAnimation');
        }
    }

    const stop = () => {
        if (containerRef.current) {
            containerRef.current.classList.add('NoAnimation');
        }
    };

    const loadCountRef = React.useRef(0);
    const onLazyLoad = () => {
        loadCountRef.current = loadCountRef.current + 1;
        if (loadCountRef.current >= props.images.length && visibleRef.current) {
            visibleRef.current.classList.add('Visible');
        }
    };

    return (
        <AutoVisible {...(props.images.length > 1) && {onEnter: () => resume(), onLeave: () => stop()}}>
            <div data-transition="midshort visibility" {...rest} ref={visibleRef} className={cn(props.className, "ImageCarousel", "Animation", {Visible: (loadCountRef.current >= props.images.length)})} {...(!lazy && {style: {opacity: 1.0}})}>
                <div data-transition="long" ref={containerRef} className="Container Animation">
                    {props.images.map((image, index) => (
                        <LazyBackground className="Img" key={index} image={image} widths={widths} lazy={lazy} onLoad={() => onLazyLoad()} />
                    ))}
                </div>
            </div>
        </AutoVisible>
    );
}
