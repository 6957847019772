import React from 'react';
import { Box } from '@mui/material';
import { AppMainLogoMinimal } from './main/AppMainLogo';
import { AppSettings } from '../AppSettings';
import './AppLoading.css';

export default function AppLoading(props: {settings: AppSettings}) {
    return (
        <Box className="AppLoading">
            <AppMainLogoMinimal settings={props.settings} />
        </Box>
    );
}