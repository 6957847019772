import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Box, Button, Paper, Typography } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AutoVisible from '../AutoVisible';
import { AppSettings } from '../AppSettings';
import AppCard from './AppCard';
import AppContact from './AppContact';
import { AppMainLogo, AppMainOurServices, AppMainPromos } from './main';
import './AppMain.css';

export interface LearnMoreDialogProps {
    open: boolean;
    id: string;
    onClose: () => void;
}

function LearnMoreDialog(props: LearnMoreDialogProps) {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        props.onClose();
    };

    return (
        <Dialog disableScrollLock={true} hideBackdrop={true} onClose={handleClose} fullScreen={fullScreen} open={props.open}>
            <DialogTitle>{props.open ? t(props.id + ".header")  : "" }</DialogTitle>
            <DialogContent>
                <Typography variant="body1" component='span' style={{whiteSpace: 'pre-line'}}>
                    <Trans i18nKey={props.open ? t(props.id + ".morecontent") : ""}
                        components={{ subheader: <Typography variant="h6" sx={{ fontWeight: '200', pb: 2 }} /> }} />
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t("dialog.action.close")}</Button>
            </DialogActions>
        </Dialog>
    );
}

interface AppMainProps {
    settings: AppSettings;
}

export default function AppMain(props: AppMainProps) {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const learnMoreID = location.state?.learnmore;

    React.useEffect(() => {
        if (location.state?.contact) {
            document.title = t('title.contact');
        } else {
            document.title = t('title.main');
        }
    }, [t, location.state]);

    return <Box data-transition="drawer" component="main" className="AppMain Animation">
        <AppMainLogo settings={props.settings} />
        <AutoVisible persist={false} component={Paper} props={{elevation: 24, square: true, className: cn("Container Animation Visible"), 'data-transition': "short visibility"}}>
            <AppCard settings={props.settings} id='cedomer' header='main.trusted.header' bodyContent='main.trusted.content' cardColor='#1c3f60' background={['card/ferry_1']} />
            <AppCard settings={props.settings} id='empowering' header='main.empowering.header' bodyContent='main.empowering.content' cardColor='#0d2538' background={['card/akersberga_3']} />
            <AppCard settings={props.settings} id='grow' header='main.grow.header' bodyContent='main.grow.content' cardColor='#1c3f60' background={['card/flowers_1']} />
            <AppCard settings={props.settings} id='technical' header='main.technical.header' bodyContent='main.technical.content' cardColor='#2e4258' background={['card/norrtalje_1']} />
            <AppCard settings={props.settings} id='towns' header='main.towns.header' subheader='main.towns.subheader' bodyContent='main.towns.content' cardColor='#1c3f60' background={['card/ljustero_1', 'card/akersberga_1', 'card/norrtalje_2']} backgroundTimer={5000}/>
            <AppCard settings={props.settings} id='itservices' header='main.whatareitservices.header' bodyContent='main.whatareitservices.content' cardColor='#2e4258' background={['card/akersberga_4']} />
            <AppMainOurServices settings={props.settings} id='ourservices' color='dark' cardColor='#afc1d0' background={['card/flowers_3']} />
            <AppMainPromos settings={props.settings} id='promos' cardColor='#0d2538' background={['card/flowers_2']} />
        </AutoVisible>

        <AppContact
            open={!!location.state?.contact}
            subject={location.state?.subject}
            onClose={() => navigate(-1)}
            />

        <LearnMoreDialog
            open={!!learnMoreID}
            id={learnMoreID}
            onClose={() => navigate(-1)} />
    </Box>;
}
