import commonData from '../assets/locales/common.json';
import seData from '../assets/locales/se/translation.json';
import enData from '../assets/locales/en/translation.json';

const resources = {
    se: {
        common: commonData,
        translation: seData
    },
    en: {
        translation: enData
    }
};

export default resources;