import React from 'react';
import { PropsWithChildren } from 'react';
import { createGlobalStyle } from 'styled-components';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import { PaletteMode } from '@mui/material';
import appSettings, { AppSettings } from './AppSettings';

declare module '@mui/material/styles' {
    interface Theme {
        text: {
            light: string;
            dark: string;
        },
        background: {
            header: string;
            drawer: string;
        };
    }
    interface ThemeOptions {
        text?: {
            light?: string;
            dark?: string;
        },
        background?: {
            header?: string;
            drawer?: string;
        };
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        dark: true;
        light: true;
    }
}

declare module '@mui/material/CircularProgress' {
    interface CircularProgressPropsColorOverrides {
        dark: true;
        light: true;
    }
}

declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        dark: true;
        light: true;
    }
}

const getDesignTokens = (mode: PaletteMode) => ({
    cssVariables: true,
    palette: {
        mode,
        light: {
            main: '#fafafa',
            light: '#f5f5f5',
            dark: '#e0e0e0',
            contrastText: '#212121',
        },
        dark: {
            main: '#212121',
            light: '#616161',
            dark: '#f5f5f5',
            contrastText: '#fafafa'
        },
        ...(mode === 'light' && {
            primary: {
                main: '#212121',
                light: '#f5f5f5',
                dark: '#424242',
                contrastText: '#fafafa'
            },
            secondary: {
                main: '#212121',
                light: '#f5f5f5',
                dark: '#424242',
                contrastText: '#fafafa'
            },
            text: {
                primary: '#212121',
                secondary: '#616161',
                disabled: '#757575'
            },
            background: {
                default: '#fefefe',
                paper: '#cadeee',
                header: '#cadeee',
                drawer: '#bbcee1'
            }
        }),
        ...(mode === 'dark' && {
            primary: {
                main: '#f5f5f5',
                light: '#f7f7f7',
                dark: '#ababab',
                contrastText: '#000000dc'
            },
            secondary: {
                main: '#f5f5f5',
                light: '#f7f7f7',
                dark: '#ababab',
                contrastText: '#000000dc'
            },
            text: {
                primary: '#fafafa',
                secondary: '#e0e0e0',
                disabled: '#bdbdbd'
            },
            background: {
                default: '#121212',
                paper: '#12121a',
                header: '#253546',
                drawer: '#1e2a36'
            }
        }),
    },
});

const GlobalStyles = createGlobalStyle`
:root {
    --header-height: ${appSettings.headerHeight}px;
    --main-card-swipe-width: ${appSettings.mainCardSwipeWidth}px;
    --main-logo-height: ${appSettings.mainLogoHeight}px;
    --main-logo-gap: ${appSettings.mainLogoGap}px;
}
`

export interface AppThemeProps {
    themeName: string;
    setTheme: (s: any) => void;
    settings: AppSettings;
};

export default function AppTheme(props: PropsWithChildren<AppThemeProps>) {
    const systemDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const darkMode = (props.themeName === 'system') ? systemDarkMode : (props.themeName === 'dark');
    const currentTheme = createTheme(getDesignTokens(darkMode ? 'dark' : 'light'))

    return (
        <ThemeProvider theme={currentTheme}>
            <CssBaseline />
            <GlobalStyles />
            {props.children}
        </ThemeProvider>
    );
}