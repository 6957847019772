import React, { PropsWithChildren } from 'react';
import AutoVisible from './AutoVisible';
import useImageLoader from '../utils/useImageLoader';

interface LazyBackgroundProps extends React.HTMLAttributes<HTMLDivElement> {
    image: string;
    lazy: boolean;
    widths?: number[]
    heights?: number[];
    innerRef?: React.RefObject<HTMLDivElement>;
    onLoad?: (src: any) => void;
}

export default function LazyBackground(props: PropsWithChildren<LazyBackgroundProps>) {
    const { image, lazy, widths, heights, children, innerRef, onLoad, ...rest } = props;

    const [ imagePath ] = useImageLoader(image, widths, heights);
    const [ loadedImage, setLoadedImage ] = React.useState<string | null>(null);

    const [ inView, setInView ] = React.useState(false);
    const loadingRef = React.useRef<null | string>(null);

    React.useEffect(() => {
        if (!lazy) {
            if (imagePath) {
                setLoadedImage(imagePath.path);
                if (onLoad) {
                    onLoad(image);
                }
            }
        } else {
            if (inView) {
                if (!imagePath || loadingRef.current === imagePath.path) {
                    return;
                }

                loadingRef.current = imagePath.path;

                let img = new Image();
                img.onload = () => {
                    if (img) {
                        setLoadedImage(img.src);
                        if (onLoad) {
                            onLoad(image);
                        }
                    }
                };

                img.src = imagePath.path;
            }
        }
    }, [image, lazy, onLoad, inView, loadingRef, imagePath]);

    const imgProps = {
        backgroundImage: (loadedImage && ('url(' + loadedImage + ')')),
        '--width': (imagePath && imagePath.width) && (imagePath.width + 'px'),
        '--height': (imagePath && imagePath.height) && (imagePath.height + 'px')
    } as React.CSSProperties;

    return (
        <AutoVisible onEnter={() => setInView(true)} onLeave={() => setInView(true)}>
            <div {...rest} ref={innerRef} style={{...rest.style, ...imgProps}}>
                {children}
            </div>
        </AutoVisible>
    );
}