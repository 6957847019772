import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import { Card, CardActions, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import AppCard from '../AppCard';
import { AppCardProps } from '../AppCard';

export default function AppMainPromos(props: AppCardProps) {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const handleBookNowClick = () => {
        navigate(location, {state: {modal: true, contact: true, subject: t("main.promos.offourservices.subject")}})
    }

    return <AppCard {...props} header='main.promos.header'>
        <Box sx={{display: 'flex', flexFlow: 'row wrap', alignItems: 'stretch', justifyContent: 'space-between', gap: 2}}>
            <Card elevation={1} sx={{ display: 'flex', flexFlow: 'column', flex: '1 0 var(--main-card-sub-width)' }}>
                <CardContent sx={{flexGrow: 1}}>
                    <Typography gutterBottom variant="h5" sx={{ fontWeight: '200', pb: 2 }}>
                        <Trans i18nKey='main.promos.consultation.header' />
                    </Typography>
                    <Typography variant="body1" component='span'>
                        <Trans i18nKey='main.promos.consultation.content' />
                    </Typography>
                </CardContent>
                <CardActions sx={{justifyContent: 'center'}}>
                    <Button variant="contained"><Trans i18nKey="main.promos.consultation.learnmore" /></Button>
                </CardActions>
            </Card>
            <Card elevation={1} sx={{ display: 'flex', flexFlow: 'column', flex: '1 0 var(--main-card-sub-width)' }}>
                <CardContent sx={{flexGrow: 1}}>
                    <Typography gutterBottom variant="h5" sx={{ fontWeight: '200', pb: 2 }}>
                        <Trans i18nKey='main.promos.offourservices.header' />
                    </Typography>
                    <Typography variant="body1" component='span'>
                        <Trans i18nKey='main.promos.offourservices.content' />
                    </Typography>
                </CardContent>
                <CardActions sx={{justifyContent: 'center'}}>
                    <Button variant="contained" onClick={() => handleBookNowClick()}><Trans i18nKey="main.promos.offourservices.booknow" /></Button>
                </CardActions>
            </Card>
        </Box>
    </AppCard>;
}