import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import { Card, CardActions, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import AppCard from '../AppCard';
import { AppCardProps } from '../AppCard';

export default function AppMainOurServices(props: AppCardProps) {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const handleLearnMoreClick = (id: string) => {
        //setLearnMoreID(id);
        navigate(location, {state: {modal: true, learnmore: id}});
    };

    const handleRequestClick = (id: string) => {
        navigate(location, {state: {modal: true, contact: true, subject: t(id + ".header")}})
    }

    return <AppCard {...props} header='main.ourservices.header'>
        <Box sx={{display: 'flex', flexFlow: 'row wrap', alignItems: 'stretch', justifyContent: 'space-between', gap: 2}}>
            <Card elevation={1} sx={{ display: 'flex', flexFlow: 'column', flex: '1 0 var(--main-card-sub-width)' }}>
                <CardContent sx={{flexGrow: 1}}>
                    <Typography gutterBottom variant="h5" sx={{ fontWeight: '200', pb: 2 }}>
                        <Trans i18nKey='main.ourservices.troubleshooting.header' />
                    </Typography>
                    <Typography variant="body1">
                        <Trans i18nKey='main.ourservices.troubleshooting.content' />
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button className="InlineButton" size="medium" onClick={() => { handleLearnMoreClick("main.ourservices.troubleshooting") }}><Trans i18nKey="main.ourservices.learnmore" /></Button>
                    <Button className="ContainedButton" size="medium" variant="contained" onClick={() => handleRequestClick('main.ourservices.troubleshooting')}><Trans i18nKey="main.ourservices.requestquote" /></Button>
                </CardActions>
            </Card>
            <Card elevation={1} sx={{ display: 'flex', flexFlow: 'column', flex: '1 0 var(--main-card-sub-width)' }}>
                <CardContent sx={{flexGrow: 1}}>
                    <Typography gutterBottom variant="h5" sx={{ fontWeight: '200', pb: 2 }}>
                        <Trans i18nKey='main.ourservices.digitalstrategy.header' />
                    </Typography>
                    <Typography variant="body1">
                        <Trans i18nKey='main.ourservices.digitalstrategy.content' />
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button className="InlineButton" size="medium" onClick={() => {handleLearnMoreClick("main.ourservices.digitalstrategy")}}><Trans i18nKey="main.ourservices.learnmore" /></Button>
                    <Button className="ContainedButton" size="medium" variant="contained" onClick={() => handleRequestClick('main.ourservices.digitalstrategy')}><Trans i18nKey="main.ourservices.requestquote" /></Button>
                </CardActions>
            </Card>
            <Card elevation={1} sx={{ display: 'flex', flexFlow: 'column', flex: '1 0 var(--main-card-sub-width)' }}>
                <CardContent sx={{flexGrow: 1}}>
                    <Typography gutterBottom variant="h5" sx={{ fontWeight: '200', pb: 2 }}>
                        <Trans i18nKey='main.ourservices.itconsultation.header' />
                    </Typography>
                    <Typography variant="body1" component='span'>
                        <Trans i18nKey='main.ourservices.itconsultation.content' />
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button className="InlineButton" size="medium" onClick={() => {handleLearnMoreClick("main.ourservices.itconsultation")}}><Trans i18nKey="main.ourservices.learnmore" /></Button>
                    <Button className="ContainedButton" size="medium" variant="contained" onClick={() => handleRequestClick('main.ourservices.itconsultation')}><Trans i18nKey="main.ourservices.requestquote" /></Button>
                </CardActions>
            </Card>
         </Box>
    </AppCard>;
}