import React from 'react';

const useScroll = (fn: () => void, deps: React.DependencyList, callFn?: boolean, callFnInEffect?: boolean) => {
    React.useEffect(() => {
        const handleScroll = () => {
            fn();
        };

        if ((callFn ?? false) && (callFnInEffect ?? true)) {
            fn();
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [deps, fn, callFn, callFnInEffect]);

    if ((callFn ?? false) && !(callFnInEffect ?? true)) {
        fn();
    }
}

export default useScroll;