const settings = {
    supported: ['se', 'en'],
    fallback: 'se',
    domain: {
        'en': 'cedomer.com',
        'se': 'cedomer.se'
    },
    google: {
        'se': 'sv',
        'en': 'en'
    },
    language: (lng) => {
        const r = settings.supported.find((l) => lng.startsWith(l));
        return r ?? settings.fallback;
    },
    captcha: (lng) => {
        const l = settings.language(lng);
        return settings.google[l] ?? l;
    }
};

module.exports = settings;