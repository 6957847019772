import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App';

import '@fontsource/roboto/latin-300.css';
import '@fontsource/roboto/latin-400.css';
import '@fontsource/roboto/latin-500.css';
import '@fontsource/roboto/latin-700.css';

import './i18n';

declare global {
    interface Window { recaptchaOptions: any; }
}

window.recaptchaOptions = {
    useRecaptchaNet: true
};

const rootEl = document.getElementById('root') as HTMLElement;
if (rootEl) {
    const root = ReactDOM.createRoot(
        rootEl
    );

    root.render(<App />);
}
