import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button, TextField, FormControlLabel, Checkbox, Input } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Alert, Snackbar, IconButton } from '@mui/material';
import { List, ListItem, ListItemText, Grow } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { useTheme } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import i18nSettings from '../../i18n/settings';
import useLocalStorage from '../../utils/useLocalStorage';
import './AppContact.css';

interface AppContactProps {
    open: boolean;
    subject?: string;
    onClose: () => void;
}

const STATE_NORMAL = 0;
const STATE_SENDING = 1;
const STATE_ERROR = 2;
const STATE_SENT = 3;

export default function AppContact(props: AppContactProps) {
    const { i18n, t } = useTranslation();
    const location = useLocation();
    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [ timeStart, setTimeStart ] = React.useState('');

    const [ state, setState ] = React.useState(STATE_NORMAL);
    const [ closing, setClosing ] = React.useState(false);

    const [ storedName, setStoredName ] = useLocalStorage('contact.name', '');
    const [ storedEmail, setStoredEmail ] = useLocalStorage('contact.email', '');
    const [ storedSubject, setStoredSubject ] = useLocalStorage('contact.subject', '');
    const [ storedMessage, setStoredMessage ] = useLocalStorage('contact.message', '');

    const onContactSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let formJson = Object.fromEntries((new FormData(event.currentTarget) as any).entries());
        formJson['lang'] = i18nSettings.language(i18n.language);

        setState(STATE_SENDING);

        const host = (window.location.hostname.includes('localhost') ? 'http://cedomer.localhost' : '');
        const url = host + '/' + (process.env.NODE_ENV === 'production' ? 'api' : 'api_dev') + '/email';
        const request = new Request(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formJson)
        });

        window.fetch(request)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        setState(STATE_SENT);
                        setStoredName('');
                        setStoredEmail('');
                        setStoredSubject('');
                        setStoredMessage('');
                    } else {
                        if (result.error === 'captcha') {
                        }

                        throw(new Error());
                    }
                },
                (error) => {
                    throw(error);
                }
            ).catch((err) => {
                setState(STATE_ERROR);
            });
    }

    const onClose = () => {
        if (state !== STATE_SENDING) {
            setClosing(true);
            props.onClose();
        }
    };

    React.useEffect(() => {
        if (location.state?.subject) {
            setStoredSubject(location.state?.subject);
            setStoredMessage('');
        }

        setTimeStart(Math.floor(Date.now() / 1000).toString());

        return () => {
            if (!props.open && closing) {
                setClosing(false);
                setState(STATE_NORMAL);
            }
        }
    }, [location.state, setStoredSubject, setStoredMessage, closing, props.open]);

    return (
        <>
            <Snackbar open={state === STATE_ERROR} autoHideDuration={5000} onClose={() => setState(STATE_NORMAL)}>
                <Alert
                    onClose={() => setState(0)}
                    severity="error"
                    action={
                        <IconButton
                            size="small"
                            aria-label={t('dialog.action.close')}
                            title={t('dialog.action.close')}
                            color="inherit"
                            onClick={() => setState(0)}
                            >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                >
                    <Trans i18nKey="main.contactform.content.error" />
                </Alert>
            </Snackbar>

            <Dialog className="AppContact" 
                open={props.open}
                fullScreen={fullScreen}
                disableScrollLock={true} hideBackdrop={true}
                onClose={() => onClose()}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => onContactSubmit(event)
                }}
                >
                {(state !== STATE_SENT) && (
                    <>
                        <DialogTitle><Trans i18nKey='main.contactform.header.main' /></DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Trans i18nKey="main.contactform.content.main" />
                            </DialogContentText>
                            <TextField autoFocus required margin="dense" fullWidth variant="standard"
                                name="name"
                                label={t('main.contactform.field.name')}
                                type="text"
                                value={storedName}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setStoredName(event.target.value)} />
                            <TextField required margin="dense" fullWidth variant="standard"
                                name="email"
                                label={t('main.contactform.field.email')}
                                type="email"
                                value={storedEmail}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setStoredEmail(event.target.value)} />
                            <TextField required margin="dense" fullWidth variant="standard"
                                name="subject"
                                label={t('main.contactform.field.subject')}
                                type="text"
                                value={location.state?.subject ?? storedSubject}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setStoredSubject(event.target.value)} />
                            <TextField required margin="dense" fullWidth variant="standard"
                                name="message"
                                label={t('main.contactform.field.message')}
                                type="text"
                                multiline={true}
                                rows={8}
                                value={storedMessage}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setStoredMessage(event.target.value)} />

                            <Input
                                name="time"
                                type="hidden"
                                autoComplete="off"
                                value={timeStart} />

                            <div style={{position: 'relative', zIndex: -1}} {...{inert: 'true'}}>
                                <FormControlLabel
                                    aria-hidden="true"
                                    control={<Checkbox tabIndex={-1} name="accept" inputProps={{autoComplete: 'off', value: '1'}} />}
                                    label={t('dialog.action.accept')} />
                            </div>
                        </DialogContent>
                        <DialogActions className="Actions">
                            <Button disabled={state === STATE_SENDING} size="large" onClick={() => onClose()}><Trans i18nKey='dialog.action.cancel' /></Button>
                            <LoadingButton loading={state === STATE_SENDING} size="large" type="submit" endIcon={<SendIcon />}><Trans i18nKey='dialog.action.send' /></LoadingButton>
                        </DialogActions>
                    </>
                )}
                {(state === STATE_SENT) && (
                    <div className="SentContainer">
                        <div className="Icon">
                            <Grow in={true} mountOnEnter unmountOnExit timeout={1000}>
                                <MarkEmailReadIcon />
                            </Grow>
                        </div>
                        <div>
                            <DialogTitle><Trans i18nKey='main.contactform.header.success' /></DialogTitle>
                            <DialogContent>
                                <DialogContentText component="span">
                                    <List>
                                        <ListItem disablePadding>
                                            <ListItemText primary={t('main.contactform.content.success')} />
                                        </ListItem>
                                    </List>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className="Actions">
                                <Button size="large" onClick={() => onClose()}><Trans i18nKey='dialog.action.close' /></Button>
                            </DialogActions>
                        </div>
                    </div>
                )}
            </Dialog>
        </>
    );
}