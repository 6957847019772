import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { Avatar, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import ScheduleIcon from '@mui/icons-material/Schedule';
import FacebookIcon from '../../icons/Facebook';
import InstagramIcon from '../../icons/Instagram';
import AppCard from './AppCard';
import { AppHeaderProps } from './AppHeader';
import i18nSettings from '../../i18n/settings';
import './AppFooter.css';

interface AppHeaderIcon {
    name: string;
    icon: typeof SvgIcon;
    title?: string;
    size?: number;
    href?: string;
    props?: object;
}

const renderIcon = (icon: AppHeaderIcon) => {
    return (
        <IconButton key={icon.name} aria-label={icon.name} title={icon.title} sx={{ml: 1, mr: 1}} {...icon.href ? {href: icon.href, target: '_blank'} : {}} {...icon.props}>
            <icon.icon className='SocialIcon' sx={{fontSize: (icon.size ? icon.size : 48)}} />
        </IconButton>
    );
};

export default function AppFooter(props: AppHeaderProps) {
    const { i18n, t } = useTranslation();

    let followIcons: AppHeaderIcon[] = [
        {name: "facebook", icon: FacebookIcon, href: t('common:link.facebook')},
        {name: "instagram", icon: InstagramIcon, href: t('common:link.instagram')}
    ];

    const parseOpenTime = (key: string) => {
        const arr = t('common:open.' + key).split(':');
        if (arr.length <= 0) {
            return '';
        }

        const date = new Date();
        date.setHours(parseInt(arr[0]));
        if (arr.length > 1) {
            date.setMinutes(parseInt(arr[1]));
        }

        const lang = i18nSettings.language(i18n.language);
        return date.toLocaleString(lang, { hour: 'numeric', minute: 'numeric', hour12: lang === 'en' });
    };

    return <AppCard settings={props.settings} data-transition="drawer" className="AppFooter Animation" component="footer" id='contact' cardColor='#2e4258' noAnimate fill>
        <Box className="Container">
            <Card elevation={1} className="Card">
                <CardContent>
                    <Typography gutterBottom variant="h5">
                        <Trans i18nKey='main.contact.contactus.header' />
                    </Typography>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton href={t('common:link.address')} target='_blank'>
                                <ListItemAvatar>
                                    <Avatar className="AvatarIcon">
                                        <PlaceIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    <Typography variant="body1">
                                        <Trans i18nKey='main.contact.contactus.address' />
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton href={t('common:link.phone')} target='_blank'>
                                <ListItemAvatar>
                                    <Avatar className="AvatarIcon">
                                        <PhoneIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    <Typography variant="body1">
                                        <Trans i18nKey='main.contact.contactus.phone' />
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton href={t('common:link.email')} target='_blank'>
                                <ListItemAvatar>
                                    <Avatar className="AvatarIcon">
                                        <EmailIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    <Typography variant="body1">
                                        <Trans i18nKey='main.contact.contactus.email' />
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
            <Card elevation={1} className="Card">
                <CardContent>
                    <Typography gutterBottom variant="h5">
                        <Trans i18nKey='main.contact.officehours.header' />
                    </Typography>
                    <List>
                        <ListItem disablePadding>
                            <ListItemAvatar>
                                <Avatar className="AvatarIcon">
                                    <ScheduleIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>
                                <Typography variant="body1">
                                    <Trans i18nKey='main.contact.officehours.content' values={{startTime: parseOpenTime('start'), endTime: parseOpenTime('end')}} />
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
            <Card elevation={1} className="Card">
                <CardContent>
                    <Typography gutterBottom variant="h5">
                        <Trans i18nKey='main.contact.followus.header' />
                    </Typography>
                    {followIcons.map((icon) => {
                        return renderIcon({title: t('main.contact.followus.title', {service: t('common:title.' + icon.name)}), ...icon});
                    })}
                </CardContent>
            </Card>
        </Box>
        <Box className="Copyright">
            <Typography component="span" gutterBottom variant="body2">
                <Trans i18nKey="footer.created" />
            </Typography>
            <Typography component="span" gutterBottom variant="body2">
                <Trans i18nKey="footer.copyright" />
            </Typography>
        </Box>
    </AppCard>;
}