import React from 'react';
import { PropsWithChildren } from 'react';
import { Waypoint } from "react-waypoint";

export interface AutoVisibleProps {
    component?: React.ComponentType;
    props?: object;
    innerRef?: React.RefObject<HTMLElement>;
    persist?: boolean;
    onEnter?: () => void;
    onLeave?: () => void;
}

interface RefType extends React.Attributes {
    ref: React.RefObject<HTMLElement> | undefined;
};

export default function AutoVisible(props: PropsWithChildren<AutoVisibleProps>) {
    const defRef = React.useRef<HTMLElement>(null);
    const compRef = props.innerRef ?? defRef;
    const persist = props.persist ?? true;

    const visible = React.useRef(false);
    React.useEffect(() => {
        if (compRef.current) {
            if (visible.current) {
                compRef.current.classList.add('Visible');
            } else if (!persist) {
                compRef.current.classList.remove('Visible');
            }
        }
    }, [visible, persist, compRef]);

    const show = () => {
        if (!visible.current) {
            visible.current = true;
            if (compRef.current) {
                compRef.current.classList.add('Visible');
            }
        }

        if (props.onEnter) {
            props.onEnter();
        }
    };

    const hide = () => {
        if (!persist && visible.current) {
            visible.current = false;
            if (compRef.current) {
                compRef.current.classList.remove('Visible');
            }
        }

        if (props.onLeave) {
            props.onLeave();
        }
    };

    return (
        <Waypoint onEnter={() => show()} onLeave={() => hide()}>
            {props.component ? (
                React.createElement(props.component ?? React.Fragment, {...props.props, ref: props.component ? compRef : undefined} as RefType, props.children)
            ) : (
                props.children
            )}
        </Waypoint>
    )
}