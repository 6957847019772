import EUFlag from 'flag-icons/flags/4x3/eu.svg';
import GBFlag from 'flag-icons/flags/4x3/gb.svg';
import SEFlag from 'flag-icons/flags/4x3/se.svg';
import i18nSettings from './settings';

const Flags: Record<string, string> = {
    'se': SEFlag,
    'en': GBFlag
};

export default function flags() {
    let ret: Array<{lang: string, icon: string}> = [];
    i18nSettings.supported.forEach((l) => {
        ret.push({lang: l, icon: Flags[l] ?? EUFlag});
    });

    return ret;
};