import * as React from 'react';

type JSONValue =
    | string
    | number
    | boolean
    | JSONObject
    | JSONArray;

interface JSONObject {
    [x: string]: JSONValue;
}

interface JSONArray extends Array<JSONValue> { }

const useLocalStorage = (storageKey: string, fallbackState: JSONValue) => {
    const needParse = (typeof fallbackState !== 'string');
    const [value, setValue] = React.useState(() => {
        const data = localStorage.getItem(storageKey);
        try {
            return data ? (needParse ? JSON.parse(data) : data) : fallbackState;
        } catch(e) {
            return fallbackState;
        }
    });

    React.useEffect(() => {
        localStorage.setItem(storageKey, needParse ? JSON.stringify(value) : value);
    }, [needParse, value, storageKey]);

    return [value, setValue];
};

export default useLocalStorage;