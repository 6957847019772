import React from 'react';

export function backgroundLoader(p: string, pfx?: string) {
    return process.env.PUBLIC_URL + '/images/background/' + (pfx ?? 'original') + '/' + p + '.webp';
}

export function imageLoader(p: string) {
    return process.env.PUBLIC_URL + '/images/' + p + '.webp';
}

export interface ImagePath {
    path: string;
    width?: number;
    height?: number;
};

export function useImageLoader(img: string, widths?: number[], heights?: number[]) {
    const haveMql = React.useRef<boolean>(false);
    const [path, setPath] = React.useState<null | ImagePath>(null);
    React.useEffect(() => {
        if (haveMql.current) {
            return;
        }

        haveMql.current = true;
        if (widths || heights) {
            const listener = (idx: number, w: boolean, m: boolean) => {
                const cur = (w ? widths : heights);
                const newIdx = (m ? idx : (idx + 1));
                if (newIdx < (cur ? cur.length : 0)) {
                    const value = (cur ? cur[newIdx] : 0);
                    setPath({path: backgroundLoader(img, (w ? 'w' : 'h') + value), ...(w ? {width: value} : {height: value})});
                } else {
                    setPath({path: backgroundLoader(img)});
                }
            };

            if (widths) {
                let smallest = widths.length;
                widths.forEach((w, index) => {
                    const mql = window.matchMedia('(max-width: ' + w + 'px)');
                    mql.onchange = (e) => listener(index, true, e.matches);
                    if (mql.matches && index <= smallest) {
                        smallest = index;
                    }
                });

                if (smallest >= 0) {
                    listener(smallest, true, true);
                }
            } else if (heights) {
                let smallest = heights.length;
                heights.forEach((h, index) => {
                    const mql = window.matchMedia('(max-height: ' + h + 'px)');
                    mql.onchange = (e) => listener(index, false, e.matches);
                    if (mql.matches && index <= smallest) {
                        smallest = index;
                    }
                });

                if (smallest >= 0) {
                    listener(smallest, false, true);
                }
            }
        } else {
            setPath({path: backgroundLoader(img)});
        }
    }, [img, widths, heights]);

    return [path];
};

export default useImageLoader;