import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppBar, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AppSettings } from '../AppSettings';
import useScroll from '../../utils/useScroll';
import { imageLoader } from '../../utils/useImageLoader';
import './AppHeader.css';

export interface AppHeaderProps {
    settings: AppSettings;
    menuOpen: boolean;
}

export function getHeaderOffsetProps(settings: AppSettings, justOffset?: boolean) {
    let ratio = ((justOffset ?? false) ? 1.0 : 0.0);
    if (ratio === 0.0) {
        const savedOffset = sessionStorage.getItem('pageYOffset');
        const pageYOffset = (savedOffset) ? parseInt(savedOffset) : window.pageYOffset;
        const mainLogoHeight = (settings.mainLogoHeight * 2) + settings.mainLogoGap;
        const logoTopOffset = (window.innerHeight / 2) - (mainLogoHeight / 2);
        const logoBottomOffset = (window.innerHeight / 2) + (mainLogoHeight / 2);
        if (pageYOffset >= logoTopOffset) {
            if (pageYOffset < logoBottomOffset) {
                const newOffset = Math.min(mainLogoHeight, pageYOffset - logoTopOffset);
                const visible = (newOffset >= 0);
                ratio = visible ? (newOffset / mainLogoHeight) : 0.0;
            } else {
                ratio = 1.0;
            }
        }
    }

    const offset = (ratio > 0.0) ? (settings.headerHeight - (ratio * settings.headerHeight)) : settings.headerHeight;
    const sopacity = ratio.toString();
    return {
        transform: 'translateY(-' + offset + 'px)',
        opacity: sopacity,
        visibility: ((ratio > 0.0) ? 'visible' : 'hidden') as any
    };
};

export default function AppHeader(props: AppHeaderProps) {
    const theme = useTheme();
    const { t } = useTranslation();

    const bgRef = React.useRef<HTMLElement>(null);
    useScroll(() => {
        const data = getHeaderOffsetProps(props.settings, props.menuOpen);
        if (bgRef.current && bgRef.current.style.opacity !== data.opacity) {
            Object.assign(bgRef.current.style, data);
        }
    }, [props.settings.headerHeight, props.menuOpen, props.settings, bgRef], true);

    const headerProps = getHeaderOffsetProps(props.settings, props.menuOpen);

    return (
        <AppBar
            ref={bgRef}
            className={"AppHeader Animation"}
            position="relative"
            color="primary"
            elevation={3}
            style={{...headerProps}}
            data-transition="drawer visibility">
            <Box className="Pre"></Box>
            <Box className="Container">
                <img src={imageLoader('logo_icon')} className="Icon" alt={t('main.logo.alt.icon')} height="40" />
                <img src={imageLoader('logo_main_' + theme.palette.mode)} className="Image" alt={t('main.logo.alt.image')} height="40" />
            </Box>
            <Box className="Post"></Box>
        </AppBar>
    );
}
