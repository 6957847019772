import React, { Suspense } from 'react';
import { RouterProvider, Route, Outlet, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CookiesProvider } from 'react-cookie';
import { default as cn } from 'classnames';
import { Box, PaletteMode } from '@mui/material';
import appSettings, { AppSettings } from './AppSettings';
import AppTheme from './AppTheme';
import AppLoading from './app/AppLoading';
import AppHeader from './app/AppHeader';
import AppMenu from './app/AppMenu';
import AppMain from './app/AppMain';
import AppFooter from './app/AppFooter';
import AppCookieBanner from './app/AppCookieBanner';
import smoothScroll from '../utils/smoothScroll';
import useLocalStorage from '../utils/useLocalStorage'
import i18nSettings from '../i18n/settings';
import './App.css';

interface AppSharedProps {
    settings: AppSettings;
    menuOpen: boolean;
    modalOpen: boolean;
    toggleColorMode: () => void;
};

function AppOverlay(props: AppSharedProps) {
    const navigate = useNavigate();
    return (
        <>
            <Box data-transition="drawer visibility" className={cn("AppOverlay", "Animation", {"Visible": (props.menuOpen || props.modalOpen)})} onClick={() => navigate(-1)} />
        </>
    )
}

function ScrollHandler(props: AppSharedProps) {
    const location = useLocation();

    const [ savedLocation, setSavedLocation ] = React.useState(location);
    React.useEffect(() => {
        window.onbeforeunload = function(e) {
            sessionStorage.setItem('pageYOffset', window.pageYOffset.toString());
        };

        const data = sessionStorage.getItem('pageYOffset');
        if (data) {
            window.scrollTo({left: 0, top: parseInt(data), behavior: 'instant' as ScrollBehavior});
            sessionStorage.removeItem('pageYOffset');
        } else {
            if (savedLocation.pathname !== location.pathname || savedLocation.hash !== location.hash || (!savedLocation.state === !location.state && savedLocation.key !== location.key)) {
                const current = location.hash.substr(1);
                if (current !== '') {
                    smoothScroll(current);
                    setSavedLocation(location);
                }
            }
        }

        if (props.menuOpen || props.modalOpen) {
            if (window.innerWidth - document.documentElement.clientWidth > 0) {
                document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + 'px');
            }
            document.documentElement.classList.add('Overlay');
        } else {
            document.documentElement.style.setProperty('--scrollbar-width', '0px');
            document.documentElement.classList.remove('Overlay');
        }
    }, [location, savedLocation, props.menuOpen, props.modalOpen]);

    return <AppOverlay {...props} />;
}

function AppDefault(props: AppSharedProps) {
    const { i18n } = useTranslation();
    const setLanguage = (l: string) => {
        i18n.changeLanguage(l);
    };

    React.useEffect(() => {
        document.documentElement.setAttribute('lang', i18nSettings.language(i18n.language));
    }, [i18n.language]);

    return (
        <>
            <ScrollHandler {...props} />
            <AppHeader {...props} />
            <AppMenu {...props} language={i18nSettings.language(i18n.language)} setLanguage={setLanguage} />
            <Outlet />
            <AppFooter {...props} />
            <AppCookieBanner />
        </>
    )
};

function SharedLayout(props: {toggleColorMode: () => void}) {
    const location = useLocation();
    const menuOpen = location.state?.menu ?? false;
    const modalOpen = location.state?.modal ?? false;

    return (
        <CookiesProvider defaultSetOptions={{path: '/'}}>
            <div className={cn('App', {MenuOpen: menuOpen})}>
                {appSettings.testSuspense ? (
                    <AppLoading settings={appSettings} />
                ) : (
                    <Suspense fallback={<AppLoading settings={appSettings} />}>
                        <AppDefault {...props} settings={appSettings} menuOpen={menuOpen} modalOpen={modalOpen} />
                    </Suspense>
                )}
            </div>
        </CookiesProvider>
    );
}

export default function App() {
    const [ themeName, setTheme ] = useLocalStorage('theme', 'system');
    const toggleColorMode = () => {
        setTheme((prevMode: PaletteMode) =>
            prevMode === 'light' ? 'dark' : 'light',
        );
    };

    const routesArr = [
        {
            path: "/",
            element: (<AppMain settings={appSettings} />)
        }
    ];

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route element={<SharedLayout toggleColorMode={toggleColorMode} />}>
                {routesArr.map((obj) => (
                    <React.Fragment key={obj.path}>
                        <Route path={obj.path} element={obj.element} />
                        {i18nSettings.supported.map((lng) => (
                            <Route key={'/' + lng + obj.path} path={"/" + lng + obj.path} element={obj.element} />
                        ))}
                    </React.Fragment>
                ))}
            </Route>
        )
    );

    return (
        <AppTheme themeName={themeName} setTheme={setTheme} settings={appSettings}>
            <RouterProvider router={router} />
        </AppTheme>
    );
}
