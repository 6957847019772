import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import Stack from '@mui/material/Stack';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './AppCookieBanner.css';

export default function AppCookieBanner() {
    const { t } = useTranslation();

    const [ cookies, setCookie ] = useCookies(['accepted']);

    const closeBanner = () => {
        let expires = new Date();
        expires.setFullYear(new Date().getFullYear() + 1);
        setCookie('accepted', true, {expires: expires});
    }

    return (
        <TrapFocus open disableAutoFocus disableEnforceFocus>
            <Fade appear={false} in={!(cookies['accepted'] ?? false)}>
                <Paper
                    className="AppCookieBanner"
                    role="dialog"
                    aria-modal="false"
                    aria-label={t('main.cookies.banner')}
                    square
                    variant="outlined"
                    tabIndex={-1}
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        m: 0,
                        p: 2,
                        borderWidth: 0,
                        borderTopWidth: 1,
                    }}
                    >
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        sx={{ justifyContent: 'space-between', gap: 2 }}
                        >
                        <Box
                            sx={{ flexShrink: 1, alignSelf: { xs: 'flex-start', sm: 'center' } }}
                            >
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                <Trans i18nKey="main.cookies.header" />
                            </Typography>
                            <Typography variant="body2">
                                <Trans i18nKey="main.cookies.content" />
                            </Typography>
                        </Box>
                        <Stack
                            direction={{
                                xs: 'row-reverse',
                                sm: 'row',
                            }}
                            sx={{
                                gap: 2,
                                flexShrink: 0,
                                alignSelf: { xs: 'flex-end', sm: 'center' },
                            }}
                            >
                            <Button size="small" onClick={closeBanner} variant="contained">
                                <Trans i18nKey="dialog.action.accept" />
                            </Button>
                        </Stack>
                    </Stack>
                </Paper>
            </Fade>
        </TrapFocus>
    );
}