const smoothScroll = (id: string, event?: React.MouseEvent<HTMLElement>) => {
    const element = document.getElementById(id);
    if (element) {
        const savedOffset = sessionStorage.getItem('pageYOffset');
        const pageYOffset = (savedOffset) ? parseInt(savedOffset) : window.pageYOffset;
        const headerOffset = parseInt(window.getComputedStyle(element).getPropertyValue('scroll-margin-top'));
        const offsetPosition = element.getBoundingClientRect().top + pageYOffset - headerOffset;
        window.scrollTo({top: offsetPosition});
        if (event) {
            event?.preventDefault();
        }
    }
};

export default smoothScroll;
